import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { useGetShopByNameQuery, useGetShopCategoriesQuery } from '../modules/shops/api';
import { getCityData } from '../utils/city-data';
import { useCity } from '../contexts/CityContext';
import CategoryItem from '../components/cards/CategoryItem';
import CategoryProductsList from '../components/lists/CategoryProductsList';
import Spinner from '../components/spinner/Spinner';

function ShopPage() {
  const { shop } = useParams();
  const cityName = useCity();

  const [activeCategory, setActiveCategory] = useState(null);

  const shopName = shop.replace('shop-', '');
  const cityData = getCityData(cityName);

  const shopParams = { city: cityData.url, shopName };
  const { data: shopData, shopIsLoading, shopIsError } = useGetShopByNameQuery(shopParams);

  const defaultCategory = {
    id: 1,
    sort: 0,
    name: 'Всё меню',
    media: {
      icon: '/assets/images/vse-tovari.png',
    },
  };

  const categoriesParams = { city: cityData.url, shop_id: shopData?.data[0]?.id };
  const { data: categoriesData, isLoading: categoriesIsLoading, isError: categoriesIsError } = useGetShopCategoriesQuery(categoriesParams);

  useEffect(() => {
    if (shopData?.data[0]) {
      const {
        id: shop_id,
        delivery_fee,
        available_for_delivery,
        minimum_sum_of_delivery_free,
        shop_has_worked
      } = shopData.data[0];

      const shopCity = cityName;

      const shopInfo = { shop_id, shopName, shopCity, delivery_fee, available_for_delivery, minimum_sum_of_delivery_free, shop_has_worked };

      localStorage.setItem('currentShopInfo', JSON.stringify(shopInfo));
      localStorage.removeItem('currentRestaurantInfo');
    }
  }, [shopName, shopData, cityName]);

  if (shopIsLoading || categoriesIsLoading) {
    return <Spinner />;
  }

  if (shopIsError || categoriesIsError) {
    return <p>Error loading data</p>;
  }

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId === defaultCategory.id ? null : categoryId);
  };

  return (
    <Layout city={cityName}>
      <div className="section-shop__container">
        <div className="section-shop__products">
          <div className="section-shop__info">
            <div className="section-shop__info-back">
              <a href={`/City-${cityData.urlName}`} className="router-link-active">
                <i className="fas fa-chevron-left"></i> Все рестораны
              </a>
            </div>
            {shopData ? (
              <div className="section-shop__info-block">
                <h1 className="section-shop__main-headin">{shopData ? shopData.data[0].name : ''}</h1>
                <ul className="row text-center">
                  <li className="section-shop__info-block-item col-12 col-sm-2">
                    <span className="section-shop__info-block-text">
                      Ресторан <b>{shopData.data[0].shop_has_worked ? 'ОТКРЫТ' : 'Закрыт'}</b>
                    </span>
                  </li>
                  <li className="section-shop__info-block-item col-12 col-sm-2">
                    <span className="section-shop__info-block-text">
                      Бесплатная доставка от <b>{shopData.data[0].minimum_sum_of_delivery_free}₽</b>
                    </span>
                  </li>
                  <li className="section-shop__info-block-item col-12 col-sm-2">
                    <span className="section-shop__info-block-text">
                      Платная доставка <b>{shopData.data[0].delivery_fee}₽</b>
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <Spinner />
            )}
            <div className="section-shop__categories">
              <ul className="row">
                <CategoryItem {...defaultCategory} isActive={activeCategory === null} onClick={() => handleCategoryClick(null)} />
                {categoriesData[0].map((category, index) => (
                  <CategoryItem
                    key={index}
                    {...category}
                    isActive={category.id === activeCategory}
                    onClick={() => handleCategoryClick(category.id)}
                  />
                ))}
              </ul>
            </div>
            <div className="section-shop__goods">
              <div className="q-gutter-sm">
                {categoriesData[0].map((category, index) => (
                  (activeCategory === null || activeCategory === category.id) && (
                    <CategoryProductsList
                      key={index}
                      city={cityName}
                      categoryId={category.id}
                      shopId={shopData?.data[0]?.id || ''}
                      categoryName={category.name}
                    />
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ShopPage;
