import React from 'react';

const RestaurantCard = ({ imageUrl, link, deliveryPrice, work_time_start, work_time_end, rating }) => {
    const convertRatingToStars = (rating) => {
        const starArray = Array.from({ length: 5 }, (_, i) => {
            const currentIndex = i;
            const isHalf = rating >= currentIndex + 0.5;
            const isFull = rating >= currentIndex + 1;
            return isFull ? 'star' : isHalf ? 'star half' : 'star-empty';
        });

        return starArray;
    };

    const stars = convertRatingToStars(rating);

    const formatWorkingHours = `с ${work_time_start} до ${work_time_end}`;

    return (
        <li className="section-restaurants__item">
            <a href={link}>
                <div className="section-restaurants__block-img">
                    <img src={imageUrl} alt="Ресторан" className="section-restaurants__img" />
                </div>
                <div className="section-restaurants__block-text restaurant-text">
                    <p className="section-restaurants__text">Бесплатная доставка от <strong>{deliveryPrice}₽</strong></p>
                    <div className="section-restaurants__text">
                        <span>Время работы <strong>{formatWorkingHours}</strong></span>
                        <div className="section-restaurants__stars-wrapper">
                            {stars.map((star, index) => (
                                <span key={index} className={star}></span>
                            ))}
                        </div>
                    </div>
                </div>
            </a>
        </li>
    );
};

export default RestaurantCard;
